import React from 'react';
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import * as Mui from '@material-ui/core';
import { createMuiTheme,  MuiThemeProvider } from '@material-ui/core/styles';
import Logo from './logo-blue.png';
import axios from 'axios';
import NumberFormat from 'react-number-format';
import AxiosModals from './axiosHandler';
import AxiosHandlerFunction from './AxiosCatchFunction';

const styles = {

    content: { // Holds the content to the right of the menu bar
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        marginTop: -30,
        width: '100%',
        flexGrow: 1,
        height: '90vh',
    },
    login:{
        border: "1px solid #9e9e9e",
        backgroundColor: '#eceff1',
        borderRadius: 20,
        margin: 'auto',
        maxWidth: 800,
        padding: 40,
    },
    button: {
        width: '100%'
    },
    container: { // Web View
        width: '30%',
    },
    mobileContainer: { // Mobile View
        width: '70%',
    },
    logo: {
        width: 200,
    },
};

const theme = createMuiTheme({
  overrides: {
      // overrides here

  },
});

interface Props {
    title: string;
}
interface States {
    userState: number; // 0 = game, 1 = pass, 2 = kinda, 3 = fail
    phoneNumber: string,
    password: string,
}

function NumberFormatCustom(props: NumberFormatCustomProps) {
    const { inputRef, onChange, ...other } = props;
    return (
        <NumberFormat
            {...other}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            format="+1 (###) ###-####" mask="_"
            isNumericString
        />
    );
  }


export default class Login extends React.Component<{}, States>{
    constructor(props: Props) {
        super(props);
        this.state = {
            phoneNumber: '',
            password: '',
            loading: false
        };  
    }
    
    clearSnackBar = () => {
        this.setState({
            snackbar: false
        });
    } 

    LoginClick = async () => {
        
        if(this.state.phoneNumber == "" || this.state.password == "" ){
            return;
        }
        
        this.setState({
            loading: true,
            errorMessage: null,
            badSignIn: false 
        });

        axios.post(window.$backendDNS + '/api/login/', {phone_number: this.state.phoneNumber, password: this.state.password})
        .then( (loginResponse) => {

                if(loginResponse.data.Failure){
                    throw(loginResponse);
                }

                localStorage.removeItem('token');
                localStorage.removeItem('admin');
                localStorage.removeItem('username');
                localStorage.setItem('token', loginResponse.data.token);
                localStorage.setItem('username', loginResponse.data.username);
                localStorage.setItem('admin', loginResponse.data.is_staff);

                if(localStorage.getItem("loginRedirect") == "Home"){
                    window.location.href="./Home";
                    localStorage.removeItem('loginRedirect');
                    return;
                }
                else {
                    window.location.href="./MyProfile";
                    localStorage.removeItem('loginRedirect');
                    return;
                }
        })
        .catch((error) => {
            const errorParse = AxiosHandlerFunction(error);
            this.setState({
                sessionModal: errorParse.sessionModal,
                snackbar: errorParse.snackbar,
                snackbarMessage: errorParse.snackbarMessage,
                snackbarIconBool: errorParse.snackbarIconBool,
                loading: false
            });
        });
    }

    //set State Var of entered username and password
    handleNumberChange = (event) => {
        this.setState({
            phoneNumber: event.target.value
        });
    }

    handlePasswordChange = (event) => {
        this.setState({
            password: event.target.value
        });
    }
   
    loadingIcon = () => {
        if(this.state.loading){
            return(
                <Mui.Grid item s={3} style={{paddingTop: 0, marginLeft:"auto", marginRight: "auto"}}>
                    <Mui.CircularProgress style={{margin:"auto"}}/>
                </Mui.Grid>
            );
        };
    }

// Returns any content for the page
    content() {
        return(
             <div style={styles.content}>
                <Mui.Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                    style={ window.innerWidth < 1000 ? styles.mobileContainer : styles.container }
                    spacing={2}
                >
                    
                    {window.innerWidth < 1000 ?
                        <Mui.Grid item s={3} style={{paddingBottom: 0}}>
                            <img
                                style={styles.logo}
                                src={Logo}
                            />
                        </Mui.Grid>
                        :
                        null
                    }

                    <Mui.Grid item s={3} style={{paddingBottom: 0}}>
                        <Mui.Typography
                            style={{ fontWeight:900, paddingBottom: 10, marginBottom: 0, color: "#303f9f"}}
                            variant={'h4'}
                            children={"Login"}
                        />
                    </Mui.Grid>

                    <Mui.Grid item s={3} style={{paddingTop: 0, width: "100%"}}>
                         <Mui.TextField
                            label="Phone Number"
                            placeholder="Phone Number"
                            margin="none"
                            variant="outlined"
                            fullWidth={true}
                            onChange={this.handleNumberChange}
                            id="formatted-numberformat-input"
                            InputProps={{
                                inputComponent: NumberFormatCustom,
                            }}
                    />
                    </Mui.Grid>

                    <Mui.Grid item s={3} style={{paddingTop: 0, width: "100%"}}>
                        <Mui.TextField
                            label="Password"
                            placeholder="Password"
                            type="password"
                            margin="normal"
                            InputLabelProps={{shrink: true}}
                            variant="outlined"
                            fullWidth={true}
                            onChange={this.handlePasswordChange}
                        />
                    </Mui.Grid>

                    <Mui.Grid item s={5} style={styles.button}>
                        <Mui.Button
                            variant="contained"
                            size="large"
                            color="primary"
                            fullWidth={true}
                            onClick={this.LoginClick}
                        >
                            Login
                        </Mui.Button>
                    </Mui.Grid>

                    <Mui.Grid item s={5} style={styles.button}>
                        <Mui.Button 
                            variant="contained"
                            size="large" 
                            fullWidth={true} 
                            component={Link}
                            to="/CreateAccount"
                        >
                            Create Account
                        </Mui.Button>
                    </Mui.Grid>

                    {this.loadingIcon()}

                </Mui.Grid>
                <AxiosModals 
                            sessionModal = { this.state.sessionModal}
                            snackbar = {this.state.snackbar}
                            snackbarMessage = {this.state.snackbarMessage}
                            snackbarIconBool = {this.state.snackbarIconBool}
                            refresh = {this.clearSnackBar}
                        />
            </div>
        );
    }

// Renders the Whole Page
    render(){
        return (
            <MuiThemeProvider theme={theme}>
                {this.content()}
            </MuiThemeProvider>
        );
    }

}