import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import RefreshIcon from '@material-ui/icons/Refresh';
import CloseIcon from '@material-ui/icons/Close';

export default class AxiosModals extends React.Component<Props, States>{    
    constructor(props: Props) {    
        super(props);
        //props: 
            //error: error to handle
            //refreshFunction: function to retry on failure
        console.log(props);
    }

    goToSignIn = () => { //go to login page
        localStorage.clear();
        window.location.href="./Login";
    };

    //refresh or acknowledge
    handleIcon = () => {
        this.props.refresh();
    }

    render(){
        console.log(this.props);
        return (
            <div>
            <Dialog
                open={this.props.sessionModal ? undefined : false}
                onClose={this.goToSignIn}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Session Expired"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                    For security reasons, your connection times out after you've been inactive for 1 hour. Click Sign In to proceed to the sign in page.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.goToSignIn} color="primary" autoFocus>
                    Sign In
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar
                anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
                }}
                open={this.props.snackbar}
                onClick={this.handleIcon}
                message={this.props.snackbarMessage}
                action={
                    <React.Fragment>
                        <IconButton size="small" aria-label="close" color="inherit" onClick={this.refresh}>
                            {this.props.snackbarIconBool ? <RefreshIcon fontSize="small" /> : <CloseIcon fontSize="small" /> }
                        </IconButton>
                    </React.Fragment>
                }
            />
            </div>
        );
    }
}