import React from 'react';
import * as Mui from '@material-ui/core';
import { createMuiTheme,  MuiThemeProvider } from '@material-ui/core/styles';
import ApprovalNugget from './ApprovalNuggets.js';
import AccessRequestNugget from './AccessRequestNugget.js';
import axios from 'axios';
import AxiosModals from './axiosHandler';
import AxiosHandlerFunction from './AxiosCatchFunction';

const styles = {
    wrapper: { // holds ALL the contnet for the page
        display: 'flex',
        backgroundColor: '#eceff1',
        height: '100vh',
        justifyContent: 'center',
    },
    content: { // Holds the content to the right of the menu bar
        display: 'flex',
        // justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        paddingTop: 30,
        width: '100%',
        flexGrow: 1,
        height: '100vh',
    },
};

const theme = createMuiTheme({
  overrides: {
      // overrides here

  },
});

interface Props {
    title: string;
}
interface States {
    userState: number; // 0 = game, 1 = pass, 2 = kinda, 3 = fail
    value: number;
    hide: boolean; 
}

export default class Admin extends React.Component<{}, States>{
    constructor(props: Props) {
		super(props);
		this.state = {
            userState: 0,
            value: 0,
            pairedImages: [],
            unpairedImages: [],
            unapprovedProfiles: [],
            loading: true,
            showError: false,
            badSession: false
        };

        this.isAdmin(); //redirect anyone who is not an admin to the map
        this.getStuffToApprove(); //on load, get stuff to approve
    }
    
    isAdmin = () => {
        axios.get(window.$backendDNS + '/api/test/', {headers: { Authorization: 'Bearer ' + localStorage.getItem("token")} })
        .catch((error) => {
            const errorParse = AxiosHandlerFunction(error);
            this.setState({
                sessionModal: errorParse.sessionModal,
                snackbar: errorParse.snackbar,
                snackbarMessage: errorParse.snackbarMessage,
                snackbarIconBool: errorParse.snackbarIconBool,
            });
        });
    }

    
    //toggles tabs
    handleChange = (event, newValue) => {
        this.setState({
            value: newValue
        });
    };

       getStuffToApprove = () => {
        //ask database for unapproved pics
        axios.get(window.$backendDNS + '/api/adminimage/', {headers: { Authorization: 'Bearer ' + localStorage.getItem("token")} })
        .then(res => {
            console.log(res);
            var unpairedImages = [];
            var pairedImages = [];
            for(var i = 0; i < res.data.length; i++){
                if(res.data[i].preID != null){
                    pairedImages.push(res.data[i]);
                }
                else {
                    unpairedImages.push(res.data[i]);
                }
            }
    
            console.log(pairedImages);
            console.log(unpairedImages);

             //ask database for unapproved profiles
            axios.get(window.$backendDNS + '/api/profileapprove/', {headers: { Authorization: 'Bearer ' + localStorage.getItem("token")} })
            .then(profiles => {
                this.setState({
                    pairedImages: pairedImages,
                    unpairedImages: unpairedImages,
                    unapprovedProfiles: profiles.data.unapproved_profiles,
                    loading: false,
                    sessionModal: false,
                    snackbar: false,
                });

                //renew the login token
                if(localStorage.getItem("token")){
                    axios.post(window.$backendDNS + '/api/token/refresh/', {refresh: localStorage.getItem("token")}) //go get the post flood image
                    .then((refresh) => {
                        if(refresh.data.token){
                            localStorage.setItem("token", refresh.data.token);
                        }
                        this.setState({
                            sessionModal: false,
                            snackbar: false
                        });
                    })
                    .catch((error) => {
                        const errorParse = AxiosHandlerFunction(error);
                        this.setState({
                            sessionModal: errorParse.sessionModal,
                            snackbar: errorParse.snackbar,
                            snackbarMessage: errorParse.snackbarMessage,
                            snackbarIconBool: errorParse.snackbarIconBool,
                            loading: false
                        });
                    });
                }

            })
            .catch((error) => {
                const errorParse = AxiosHandlerFunction(error);
                this.setState({
                    sessionModal: errorParse.sessionModal,
                    snackbar: errorParse.snackbar,
                    snackbarMessage: errorParse.snackbarMessage,
                    snackbarIconBool: errorParse.snackbarIconBool,
                    loading: false
                });
            });
        })
        .catch((error) => {
            const errorParse = AxiosHandlerFunction(error);
            this.setState({
                sessionModal: errorParse.sessionModal,
                snackbar: errorParse.snackbar,
                snackbarMessage: errorParse.snackbarMessage,
                snackbarIconBool: errorParse.snackbarIconBool,
                loading: false
            });
        });
    };

    triggerError = (error) => {
        const errorParse = AxiosHandlerFunction(error);
        this.setState({
            sessionModal: errorParse.sessionModal,
            snackbar: errorParse.snackbar,
            snackbarMessage: errorParse.snackbarMessage,
            snackbarIconBool: errorParse.snackbarIconBool,
            loading: false
        });
    }
   

    pairedPhotos = () => {
        
        if(this.state.loading){
            return (
                <Mui.LinearProgress />
            );
        }
        else if (this.state.pairedImages.length == 0){
            return (
                <Mui.Typography>
                    No New Photos
                </Mui.Typography>
            );
        }
        else {
            return (
                this.state.pairedImages.map((imageObject) =>
                    <ApprovalNugget image={imageObject} error={this.triggerError} expired={this.expiredToken}/>
                )
            );
        }
    }

    unpairedPhotos = () => {
        
        if(this.state.loading){
            return (
                <Mui.LinearProgress />
            );
        }
        else if (this.state.unpairedImages.length == 0){
            return (
                <Mui.Typography>
                    No New Photos
                </Mui.Typography>
            );
        }
        else {
            return (
                this.state.unpairedImages.map((imageObject) =>
                <ApprovalNugget image={imageObject} error={this.triggerError} expired={this.expiredToken}/>
                )
            );
        }
    }

    profiles = () => {
        if(this.state.loading){
            return (
                <Mui.LinearProgress />
            );
        }
        else if (this.state.unapprovedProfiles.length == 0){
            return (
                <Mui.Typography>
                    No New Profiles
                </Mui.Typography>
            );
        }
        else {
            return (
                <Mui.Grid container>
                {this.state.unapprovedProfiles.map((profile) =>
                    <AccessRequestNugget username={profile.username} id={profile.id} error={this.triggerError} expired={this.expiredToken}/>
                )}
                </Mui.Grid>
            );
        }
    }

// Returns any content for the page
    content() {
        return(
            <div style={styles.content}>
                <Mui.Tabs
                    value={this.state.value}
                    onChange={this.handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                    variant="fullWidth"
                    style={{width: '90%'}}
                >
                    <Mui.Tab label="Paired Images" />
                    <Mui.Tab label="Un-Paired Images" />
                    <Mui.Tab label="Account Requests" />
                </Mui.Tabs>

                <div hidden={this.state.value !== 0} index={0} style={{width: "100%", paddingTop: 30}}>
                   {this.pairedPhotos()}
                </div>

                <div hidden={this.state.value !== 1} index={1} style={{width: "100%", paddingTop: 30}}>
                    {this.unpairedPhotos()}
                </div>

                <div hidden={this.state.value !== 2} index={2} style={{width: '100%', paddingTop: 30}}>
                    {this.profiles()}    
                </div>
                <AxiosModals 
                    sessionModal = { this.state.sessionModal}
                    snackbar = {this.state.snackbar}
                    snackbarMessage = {this.state.snackbarMessage}
                    snackbarIconBool = {this.state.snackbarIconBool}
                    refresh = {this.getStuffToApprove}
                />
            </div>
        );
    }

// Renders the Whole Page
    render(){
        return(
            <MuiThemeProvider theme={theme}>
                {this.content()}
            </MuiThemeProvider>
        )
    }
}
