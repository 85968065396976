
export default function AxiosHandlerFunction (error) {
    if(!error){
        return {
            sessionModal: false,
            snackbar: false,
            snackbarIconBool: true,
            snackbarMessage: null,
        };
    }
    if(error.response){
        if(error.response.data){
            if(error.response.data.detail){
                if(error.response.data.detail == "Signature has expired"){ //expired token, end the session
                    return {
                        sessionModal: true,
                        snackbar: false,
                        snackbarIconBool: true,
                        snackbarMessage: null,
                    };
                }
                else if(error.response.data.detail == "You do not have permission to perform this action."){ //you can't do that (test and test2), direct them away and back to the sign-in page
                    localStorage.clear();
                    window.location.href="./Login";
                    return null;
                }
                else if(error.response.data.detail == "Error decoding signature."){ //corrupted token, end the session
                    return {
                        sessionModal: true,
                        snackbar: false,
                        snackbarIconBool: true,
                        snackbarMessage: null,
                    };
                }
                else if (error.response.data.detail == "Authentication credentials were not provided."){ //no token, end the session
                    return {
                        sessionModal: true,
                        snackbar: false,
                        snackbarIconBool: true,
                        snackbarMessage: null,
                    };
                }
                else { //don't know, throw a generic error
                    return {
                        sessionModal: false,
                        snackbar: true,
                        snackbarIconBool: true,
                        snackbarMessage: "Server Error. Please log in again"
                    };
                }
            }
            else if(error.response.data.non_field_errors){
                if(error.response.data.non_field_errors[0] == "Error decoding signature."){ //tried to refresh a bad token... end the session
                    return {
                        sessionModal: true,
                        snackbar: false,
                        snackbarIconBool: true,
                        snackbarMessage: null,
                    };
                }
                else { //don't know, throw a generic error
                    return {
                        sessionModal: false,
                        snackbar: true,
                        snackbarIconBool: true,
                        snackbarMessage: "Server Error. Please log in again"
                    };
                }
            }
            else {
                return {
                    sessionModal: false,
                    snackbar: true,
                    snackbarIconBool: true,
                    snackbarMessage: "Server Error. Please log in again"
                };
            }
        }
        else{
            return {
                sessionModal: false,
                snackbar: true,
                snackbarIconBool: true,
                snackbarMessage: "Server Error. Please log in again"
            };
        }
    }
    else if (error.data){
        if(error.data.Failure){
            if(error.data.Failure == "this user is banned"){ //logged in user is banned
                return {
                    sessionModal: false,
                    snackbar: true,
                    snackbarIconBool: false,
                    snackbarMessage: "That user is banned"
                };
            }
            else if(error.data.Failure == "A user with this username already exists"){ //cant make account, pick a new username
                return {
                    sessionModal: false,
                    snackbar: true,
                    snackbarIconBool: false,
                    snackbarMessage: "That username is taken, please pick a new one"
                };
            }
            else if(error.data.Failure == "A user with this phone_number already exists"){ //can't make account, phone number exists
                return {
                    sessionModal: false,
                    snackbar: true,
                    snackbarIconBool: false,
                    snackbarMessage: "There is already an account registered to that phone number"
                };
            }
            else if(error.data.Failure == "Sent incorrect data"){ //for create an account  no account found --
                return {
                    sessionModal: false,
                    snackbar: true,
                    snackbarIconBool: false,
                    snackbarMessage: "Those credentials do not correspond with an account"
                };
            }
            else if(error.data.Failure == "Failed to send password"){
                return {
                    sessionModal: false,
                    snackbar: true,
                    snackbarIconBool: false,
                    snackbarMessage: "Those credentials do not correspond with an account"
                };
            }
            else if(error.data.Failure == "Incorrect information sent"){
                return {
                    sessionModal: false,
                    snackbar: true,
                    snackbarIconBool: false,
                    snackbarMessage: "Those credentials do not correspond with an account"
                };
            }
            else if(error.data.Failure == "Can't find phone_number"){
                return {
                    sessionModal: false,
                    snackbar: true,
                    snackbarIconBool: false,
                    snackbarMessage: "Those credentials do not correspond with an account"
                };
            }
            else if(error.data.Failure == "Login issue"){
                return {
                    sessionModal: false,
                    snackbar: true,
                    snackbarIconBool: false,
                    snackbarMessage: "Server Error. Please log in again"
                };
            }
            else if(error.data.Failure == "Incorrect Password"){
                return {
                    sessionModal: false,
                    snackbar: true,
                    snackbarIconBool: false,
                    snackbarMessage: "Those credentials do not correspond with an account"
                };
            }
            else{
                return {
                    sessionModal: false,
                    snackbar: true,
                    snackbarIconBool: true,
                    snackbarMessage: "Server Error. Please try again"
                };
            }
        }
    }
    else{
        return {
            sessionModal: false,
            snackbar: true,
            snackbarIconBool: true,
            snackbarMessage: "Server Error. Please log in again"
        };
    }
} 